import axios from 'axios'

export default class RestHelper{
    static async RestSend(procName,opJob,parameters=[{}])
    {
        const data =  {
            "_header": {
                    "procName": procName,
                    "opJob": opJob, 
                    //"opUser": "100001",  
                    //"userIp": "0.0.0.0", 
                    //"access_token": "04c0a39f-cd64-4390-8785-1959edb62a92"
                },
            "_data": {
                "parameters": parameters
            }
            //"i_OPJOB": opJob,
            //"procName": procName,
            //"parameters": parameters,
        }
        const res = await axios.post('https://bimilserver.bimpeers.com/BIMIL_ADMIN',data);
        //const res = await axios.post('https://bimilservertest.bimpeers.com/BIMIL_ADMIN',data);
        //const res = await axios.post(this.$SERVERURL + 'BIMIL_ADMIN',data);
        //const res = await axios.post('http://localhost:12008/BIMIL_ADMIN',data);
        return res.data;
    }
    static async GetHomeData() {
        return await this.RestSend("USP_HOME", "HOME_COUNT")
    }
    static async GetAddinCount(parameters) {
        return await this.RestSend("USP_EXECUTE_INFO","EXECUTE_COUNT", parameters)
    }
    static async GetAddinCount_date(parameters) {
        return await this.RestSend("USP_EXECUTE_INFO","EXECUTE_COUNT_DATE", parameters)
    }
    static async GetAddinCountMonth(parameters) {
        return await this.RestSend("USP_EXECUTE_INFO","EXECUTE_COUNT_MONTH", parameters)
    }
    static async GetAddinInit() {
        return await this.RestSend("USP_EXECUTE_INFO", "EXECUTE_COUNT_INIT")
    }
    static async GetJoinCount(parameters) {
        return await this.RestSend("USP_JOIN_INFO", "JOIN_COUNT", parameters)
    }
    static async GetJoinCountMonth(parameters) {
        return await this.RestSend("USP_JOIN_INFO", "JOIN_COUNT_MONTH", parameters)
    }
    static async GetAddinData(parameters) {
        return await this.RestSend("USP_EXECUTE_INFO", "EXECUTE_INFO", parameters)
    }
    static async GetUserData() {
        return await this.RestSend("USP_JOIN_INFO", "USER_INFO");
    }
    static async GetAddinChart(parameters) {
        return await this.RestSend("USP_EXECUTE_INFO", "EXECUTE_CHART", parameters);
    }
    static async GetAddinChart_time(parameters) {
        return await this.RestSend("USP_EXECUTE_INFO", "EXECUTE_CHART_TIME", parameters);
    }
    static async GetAddinChart_datetime(parameters) {
        return await this.RestSend("USP_EXECUTE_INFO", "EXECUTE_CHART_TIME_DATE", parameters);
    }
    static async GetAddinChartMonth(parameters) {
        return await this.RestSend("USP_EXECUTE_INFO", "EXECUTE_CHART_MONTH", parameters);
    }
    static async GetVisitData(parameters) {
        return await this.RestSend("USP_VISIT_INFO", "VISIT_COUNT", parameters);
    }
    static async GetVisitDataMonth(parameters) {
        return await this.RestSend("USP_VISIT_INFO", "VISIT_COUNT_MONTH", parameters);
    }
    static async GetAddinDownloadData(parameters) {
        return await this.RestSend("USP_DOWNLOAD_INFO", "ADDIN_DOWNLOAD", parameters);
    }
    static async GetAddinDownloadData_date(parameters) {
        return await this.RestSend("USP_DOWNLOAD_INFO", "ADDIN_DOWNLOAD_DATE", parameters);
    }
    static async GetDownloadChart(parameters) {
        return await this.RestSend("USP_DOWNLOAD_INFO", "DOWNLOAD_CHART", parameters);
    }
    static async GetDownloadChart_time(parameters) {
        return await this.RestSend("USP_DOWNLOAD_INFO", "ADDIN_DOWNLOAD_TIME", parameters)
    }
    static async GetDownloadChart_datetime(parameters) {
        return await this.RestSend("USP_DOWNLOAD_INFO", "ADDIN_DOWNLOAD_TIME_DATE", parameters)
    }
    static async GetAddinDownloadDataMonth(parameters) {
        return await this.RestSend("USP_DOWNLOAD_INFO", "ADDIN_DOWNLOAD_MONTH", parameters);
    }
    static async GetDownloadChartMonth(parameters) {
        return await this.RestSend("USP_DOWNLOAD_INFO", "DOWNLOAD_CHART_MONTH", parameters);
    }
    static async GetAddinDownloadInit() {
        return await this.RestSend("USP_DOWNLOAD_INFO", "ADDIN_DOWNLOAD_INIT");
    }
}