<template>
  <router-view></router-view>
</template>

<script>

export default {
  components: {
  },
  data(){
    return {

    }
  },
  mounted(){

  },
  methods :{

  }
}
</script>

<style>
*{
  margin: 0px;
  padding: 0px;
}
html{
  font-size: 62.5%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>


<style>
</style>
