<template>
  <div class="w-full h-full">
    <DxTabPanel id="tapPanel" class="w-full h-full"
        :animation-enabled="true"
        :repaint-changes-only="true"
        :data-source="items"
        :selectedItem="selectedItem"
        :swipe-enabled="false"
        :styling-mode="'primary'"
        >
        <template #title="{ data: index }">
            <div class="flex justify-between" style="display: none;">
                <p class="text-lg font-bold font-NanumGothicCoding">{{ index }}</p>
                <svg @click="tapClose(index)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 ml-2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
        </template>
        <template #itemTemplate="{ data: index }">
            <div class="m-0 p-0">
                <JoinCount v-if="index=='가입 인원'"/>
                <USERINFO_View v-if="index=='회원정보'"/>
                <AddinExecuteCount v-if="index=='애드인 실행 횟수'"/>
                <VISIT_View v-if="index=='접속 인원'"/>
                <AddinExecuteState v-if="index=='애드인 실행 현황'"/>
                <AddinDownload type="info" v-if="index=='애드인 다운로드'"/>
                <Home_View v-if="index=='Home'"/>
            </div>
        </template>
    </DxTabPanel>
  </div>
</template>

<script>
import DxTabPanel from 'devextreme-vue/tab-panel';
import AddinDownload from './Views/AddinDownload.vue'
import Home_View from './Views/Home_View'
import JoinCount from './Views/JoinCount.vue';
import AddinExecuteCount from './Views/AddinExecuteCount.vue';
import USERINFO_View from './Views/USERINFO_View.vue';
import AddinExecuteState from './Views/AddinExecuteState.vue';
import VISIT_View from './Views/VISIT_View.vue';

export default {
    components: {
        DxTabPanel,
        AddinExecuteState,
        Home_View,
        JoinCount,
        AddinExecuteCount,
        USERINFO_View,
        VISIT_View,
        AddinDownload
    },
    data(){
        return{
            items : ['Home',],//'매출 입력','수주 현황','매출 현황'
            selectedItem : ""
        }
    },
    methods :{
        tapClose(index)
        {
            this.items = this.items.filter(x=>x != index);
        }
    }
}
</script>

<style>
#tapPanel > .dx-tabpanel-tabs{
    display: none !important;
}
.dx-pivotgrid-container .dx-expand-icon-container{
    display: none !important;
    width: 0 !important;
    height: 0 !important;
}
.dx-pivotgrid-container .dx-expand{
    display: none !important;
    width: 0 !important;
    height: 0 !important;
}
</style>