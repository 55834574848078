<template>
  <div>
    <DxToolbar id="toolbar" class="bg-[#ffffff] border-b-[2px]">
        <DxItem
            location="before"
            widget="dxButton"
            :options = "{icon: 'menu',id : 'test', onClick: () => { this.openState = !this.openState; }}"
        />
        <DxItem location="before" locate-in-menu="never">
            <img src="../assets/logo.png" style="width: 30px; height: auto;">
            <div class="text-[#006EDD] text-[1.5rem] tracking-[0px] ml-6 font-Inter font-medium">BIMIL 관리자 시스템</div>
        </DxItem>
    </DxToolbar>
    <DxDrawer 
      :opened="openState"
      height="calc(100vh - 56px)"
      :close-on-outside-click="false"
      template="listMenu">
      <template #listMenu>
        <div class="w-[18rem] h-full border-r-2 box-border">
            <NavigationList ref="navList" @onChangeItem="onChangeItem"/>
        </div>
      </template>
      <div id="content" class="w-full h-full bg-white">
        <Contents ref="contents"/>
      </div>
    </DxDrawer>
  </div>
</template>

<script>
import DxDrawer from 'devextreme-vue/drawer';
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import NavigationList from './NavigationList.vue';
import Contents from './Contents.vue'

export default {
    components : {
        DxDrawer,
        DxToolbar,
        DxItem,
        NavigationList,
        Contents
    },
    computed:{
        contents(){
            return this.$refs.contents;
        },
        naviList()
        {
            return this.$refs.navList;
        }
    },
    watch :{
        openState(){
            // console.log(this.openState)
        }
    },
    data(){
        return{
            openState: true,
            toolbarContent: [
                {
                    widget: 'dxButton',
                    location: 'before',
                    options: {
                        icon: 'menu',
                        onClick: () => { this.openState = !this.openState; },
                    },
                },
            ],
        }
    },
    methods :{
        onChangeItem(itemName)
        {
            const temp = []
            if(temp.find(x=>x==itemName))
            {
                this.contents.selectedItem = itemName;
                return;
            }
            temp.push(itemName);
            this.contents.items = temp;
            this.contents.selectedItem = itemName;
        }
    },
    
}
</script>

<style>
#toolbar{
      padding: 0px 1.6rem;
      height: 56px;
      box-sizing: border-box;
  }
  #toolbar .dx-button
  {
      background: transparent;
      border-color: transparent;
  }
  #toolbar .dx-button .dx-icon-menu{
      font-size: 2.5rem;
      text-align: start;
  }
  #toolbar .dx-button .dx-button-content .dx-icon{
      color: #2B3133;
  }
  .panel-list .dx-list-item {
      color: #5a5a5a;
      background: transparent;
      border-block-color: #a1a1a1;
      font-size: 1.2rem;
      font-weight: bold;
  }
  .panel-list .dx-list-item:hover{
      color: #000000;
  }
  .panel-list .dx-list-item .dx-icon {
      color: #fff !important;
  }
  .dx-toolbar-before {
      display: flex !important;
      flex-direction:row !important;
      align-items: center !important;
  }
  .dx-toolbar-item-content {
      display:flex !important;
      align-items: center !important;
      margin-right: 32px;
  }
/* .dx-scrollable-scroll{
    height: 2rem !important;
}
.dx-scrollable-scroll-content{
    height: 2rem !important;
}
.dx-scrollable-scrollbar{
    height: 2rem !important;
} */
</style>