<template>
    <div class="Navigation_Wrapper">
        <div class="text-[1.3rem] mt-[25px] mb-[1rem] font-bold text-[#fff] w-full font-Inter tracking-tighter pl-10" @click="onItemClick('Home')">HOME</div>
        <div class="text-[1.3rem] mt-[2rem] mb-[1rem] font-bold text-[#fff] w-full font-Inter tracking-tighter pl-10">현황</div>
        <div class="flex flex-col items-start w-full h-auto leading-[3.5rem]">
            <div class="select-none flex flex-row items-center font-Inter text-[1.3rem] pl-10 w-full" v-for="item in infos" :key="item">
                <div v-if="selItemName === item"></div>
                <div :style="{color : selItemName === item ? '#ffffff' : '#fff', background: selItemName === item ? '#006EDD' : 'rgba(0,0,0,0)', width : selItemName === item ? '160px' : 'auto',
            position:selItemName === item ? 'relative' : 'unset', padding:selItemName === item ? '0 15px' : '0'}" class="tracking-tighter rounded-lg right-[15px]" @click="onItemClick(item)">{{item}}</div>
            </div>
        </div>
        <div class="text-[1.3rem] mt-[2rem] mb-[1rem] font-bold text-[#fff] w-full font-Inter  pl-10">관리</div>
        <div class="flex flex-col items-start w-full h-auto leading-[3.5rem]">
            <div class="select-none flex flex-row items-center font-Inter text-[1.3rem]  pl-10 w-full" v-for="item in manages" :key="item">
                <div v-if="selItemName === item"></div>
                <div :style="{color : selItemName === item ? '#ffffff' : '#fff', background: selItemName === item ? '#006EDD' : 'rgba(0,0,0,0)', width : selItemName === item ? '160px' : 'auto',
                position:selItemName === item ? 'relative' : 'unset', padding:selItemName === item ? '0 15px' : '0'}" class="tracking-tighter rounded-lg right-[15px]" @click="onItemClick(item)">{{item}}</div>
            </div>
        </div>
    </div>
</template>
<script>
import { DxList } from 'devextreme-vue/list';

export default {
    components: {
        DxList,
    },
    data() {
        return {
            infos : [
                '가입 인원',
                '접속 인원',
                '애드인 실행 횟수',
                '애드인 다운로드'
            ],
            manages : [
                '회원정보',
                '애드인 실행 현황'
            ]
        };
    },
    mounted()
    {
        this.$nextTick(()=>{
            // this.selItemName = this.manages[1];
        })
    },
    methods:{
        onItemClick(itemName)
        {
            this.selItemName = itemName;
            this.$emit('onChangeItem',itemName)
        },
    }
};
</script>

<style scoped>
.Navigation_Wrapper {
    position:absolute;
    top:0;
    left:0;
    width: 180px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    align-items: center;
    background:#002f6c;
    color:#fff;
    
}
</style>
      