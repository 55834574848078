<template>
    <div class="w-auto h-full bg-white p-[2rem] font-Inter px-6 py-11" id="USER_View">
        <div class="flex flex-row justify-between">
        <p class="text-[2rem] font-black text-[#2B3133] tracking-[0px]">회원 정보</p>
        <DxButton class="float-right"
              :width="130"
              text="Filter Reset"
              type="normal"
              styling-mode="contained"
              @click="refresh"
            />
        </div>
        <!-- <p class="text=[2rem] py-6">총 {{ userCount }} 건 중 {{ userCount }}</p> -->
        <div class="flex-col pt-[3rem]">
        <DxDataGrid id="UserInfoGrid" ref="dataGrid" :height="`auto`" width="100%" :allow-column-resizing="true" :data-source="data" :show-borders="true">
            <DxPaging :enabled="true" :page-size="20" />
            <DxHeaderFilter :visible="true" />
            <DxColumn caption="ID" data-field="Id" :width="'8%'" alignment="center"/>
            <DxColumn caption="Email" data-field="Email" width="20%" alignment="center"/>
            <DxColumn caption="Name" data-field="Name" width="15%" alignment="center"/>
            <DxColumn caption="Role" data-field="Role" width="15%" alignment="center"/>
            <DxColumn caption="Company" data-field="Company" width="20%" alignment="center"/>
            <DxColumn caption="Register Date" data-field="RegDate" :width="'20%'" alignment="center"/>
            <DxColumn caption="Last Visited" data-field="VisitedDate" :width="'20%'" alignment="center"/>
        </DxDataGrid>
        </div>

    </div>
</template>

<script>
import DxButton, { DxButtonTypes } from 'devextreme-vue/button';
import {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxSearchPanel,
    DxHeaderFilter,
    DxLookup,
    DxScrolling,
    DxToolbar,
    DxSelection,
    DxMasterDetail,
    DxColumnChooser,
    DxLoadPanel
} from 'devextreme-vue/data-grid';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import DxForm, { DxItem, DxGroupItem } from 'devextreme-vue/form';
import DataHelper from '../Helpers/DataHelper'
import {
    DxBarGauge, DxLabel, DxExport, DxTitle, DxFont, DxSize, DxMargin, DxLegend, DxTooltip
} from 'devextreme-vue/bar-gauge'; //막대 게이지


export default {
    components: {
        DxBarGauge,
        DxSize,
        DxExport,
        DxMargin,
        DxTitle,
        DxFont,
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxEditing,
        DxPopup,
        DxSearchPanel,
        DxHeaderFilter,
        DxLookup,
        DxForm,
        DxScrolling,
        DxToolbar,
        DxSelection,
        DxLoadPanel,
        DxToolbarItem,
        DxGroupItem,
        DxLabel,
        DxMasterDetail,
        DxItem,
        DxColumnChooser,
        DxLegend,
        DxTooltip,
        DxButton
    },
    props: {
        type: String,
    },
    computed: {

        dataGrid() {
            return this.$refs.dataGrid.instance;
        },
        textSize() {
            return ['1rem', '1.2rem', '1.4rem']
        }
    },
    data() {
        return {
            data: [],
            originData: {},
            selectedData: null,
            validationGroupName: "gridForm",
            editPopupVisible: false,
            userCount: '',
            textType: '1'
        }
    },
    watch: {
    },
    async mounted() {
        await this.initData();
    },
    methods: {
        async initData() {
            this.textType = 1;
            this.data = await DataHelper.InitUserData();
            this.userCount = this.data["length"];
            this.dataGrid.repaint();
        },
        onScrollGrid() {
            this.dataGrid.component.collapseAll(-1)
        },
        onSelectionChanged(e) {
            const data = e?.selectedRowsData[0];
            this.selectedData = data;
            e.component.collapseAll(-1)
            e.component.expandRow(e.currentSelectedRowKeys[0]);
        },
        refresh() {
            this.dataGrid.clearSorting();
            this.dataGrid.clearFilter();
        }
    }
}
</script>

<style>
#OmGrid .dx-toolbar-items-container {
    height: 0.8rem;
}

#OmGrid .dx-item-content .dx-button-content {
    visibility: hidden;
    display: none;
}

#OmGrid .dx-item-content .dx-widget {
    visibility: hidden;
    display: none;
}

#OmGrid td {
    padding: 3px 5px;
    border: 1px solid #e0e0e0 !important;
}

#OmGrid .dx-header-row {
    height: 25px;
    text-align: center;
    width: auto;
    padding-left: 8px;
    font-size: 1.2rem;
}

#OmGrid .dx-master-detail-cell {
    padding: 0px;
}

.dx-datagrid .dx-column-indicators {
    float: left !important;
    padding-right: 5px;
}

#UserInfoGrid .dx-toolbar-items-container {
    height: 0.8rem;
}

#UserInfoGrid .dx-item-content .dx-button-content {
    visibility: hidden;
    display: none;
}

#UserInfoGrid .dx-item-content .dx-widget {
    visibility: hidden;
    display: none;
}

#UserInfoGrid td {
    padding: 6px 5px;
    border: 1px solid #e0e0e0 !important;
}

#UserInfoGrid .dx-header-row {
    height: 30px;
    text-align: center;
    width: auto;
    padding-left: 8px;
    font-size: 1.2rem;
}

#UserInfoGrid .dx-master-detail-cell {
    padding: 0px;
}

#UserInfoGrid .dx-pager .dx-pages  {
    display: flex !important;
    justify-content: center !important;
    float: unset;
}

</style>