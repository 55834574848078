import { createApp } from 'vue'
import App from './App.vue'
import 'devextreme/dist/css/dx.material.blue.light.css';
import './index.css'
import router from './router'
import PrimeVue from 'primevue/config'; // here
import 'primevue/resources/themes/saga-blue/theme.css'; // 테마 파일
import 'primevue/resources/primevue.min.css'; // core css
import 'primeicons/primeicons.css'; // icons
import 'primeflex/primeflex.css';

const app = createApp(App).use(router);

app.use(PrimeVue, { unstyled: true });

app.config.globalProperties.$viewMap = new Map();
app.config.globalProperties.$pmData = [];

//빔피어스 실서버
//app.config.globalProperties.$SERVERURL = "https://bimilserver.bimpeers.com/";
//로컬서버
app.config.globalProperties.$SERVERURL = "http://localhost:12008/";

app.mount('#app')