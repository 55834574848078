<template>
    <div class="wrapper">
        <div class="Content_Container">
            <div class="item_Container">
                <div class="item">
                    <p>총 가입 인원 {{ joinWhole }} 명</p>
                </div>
                <div class="item">
                    <p>오늘 가입 인원 {{ joinToday }} 명</p>
                </div>
                <div class="item">
                    <p>오늘 접속 인원 {{ visitToday }} 명</p>
                </div>
            </div>
            <div class="table_container">
                <div class="table_down">
                    <div class="flex flex-row justify-between">
                        <p class="text-[2rem] font-black font-Inter text-[#2B3133] tracking-tighter mb-[10px]">애드인 다운로드 수(누적)</p>
                    </div>
                    <div class="flex flex-col">
                        <DxDataGrid id="OmGrid" ref="dataGrid" :class="``" :height="`auto`"
                            :allow-column-resizing="true" :width="`auto`" :column-resizing-mode="'currentMode'"
                            :data-source="dataSource" :show-borders="true">
                            <DxPaging :enabled="false" />
                            <!-- <DxSelection mode="single" /> -->
                            <!-- <DxSearchPanel :visible="false" :width="240" placeholder="Search..." /> -->
                            <!-- <DxEditing mode="popup" :allow-updating="false" :allow-adding="true"
                                    :allow-deleting="false">
                                </DxEditing> -->
                            <DxHeaderFilter :visible="true" />
                            <DxScrolling mode="'virtual" />
                            <DxColumn caption="번호" data-field="rownum" alignment="center" />
                            <DxColumn caption="애드인명" data-field="AddinName" alignment="center" />
                            <DxColumn caption="다운로드 수" data-field="InstallCount" alignment="center" />
                        </DxDataGrid>
                    </div>
                </div>
                <div class="table_execute">
                    <div class="flex flex-row justify-between">
                        <p class="text-[2rem] font-black font-Inter text-[#2B3133] tracking-tighter mb-[10px]">애드인 실행 횟수(누적)</p>
                    </div>
                        <div class="flex flex-col">
                            <DxDataGrid id="OmGrid" ref="dataGrid" :class="``" :height="`auto`"
                                :allow-column-resizing="true" :width="`auto`" :column-resizing-mode="'currentMode'"
                                :data-source="dataSource2" :show-borders="true">
                                <DxPaging :enabled="false" />
                                <!-- <DxSelection mode="single" />
                                <DxSearchPanel :visible="false" :width="240" placeholder="Search..." />
                                <DxEditing mode="popup" :allow-updating="false" :allow-adding="true"
                                    :allow-deleting="false">
                                </DxEditing> -->
                                <DxHeaderFilter :visible="true" />
                                <DxScrolling mode="'virtual" />
                                <DxLoadPanel :enabled="true" />
                                <DxColumn caption="번호" :width="auto" data-field="rownum" alignment="center" />
                                <DxColumn caption="애드인명" :width="auto" data-field="AddinName"  alignment="center"/>
                                <DxColumn caption="기능명" :width="auto" data-field="CommandName"  alignment="center"/>
                                <DxColumn caption="실행횟수" :width="auto" data-field="ExecuteCount"  alignment="center"/>
                            </DxDataGrid>
                        </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxSearchPanel,
    DxHeaderFilter,
    DxLookup,
    DxScrolling,
    DxToolbar,
    DxSelection,
    DxMasterDetail,
    DxColumnChooser,
    DxSelectBox,
    DxLoadPanel
} from 'devextreme-vue/data-grid';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import DxForm, { DxItem, DxGroupItem } from 'devextreme-vue/form';
import DataHelper from '../Helpers/DataHelper'
import {
    DxBarGauge, DxLabel, DxExport, DxTitle, DxFont, DxSize, DxMargin, DxLegend, DxTooltip
} from 'devextreme-vue/bar-gauge'; //막대 게이지

export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxEditing,
        DxPopup,
        DxSearchPanel,
        DxHeaderFilter,
        DxLookup,
        DxForm,
        DxScrolling,
        DxToolbar,
        DxSelection,
        DxLoadPanel,
        DxBarGauge,
        DxLabel, DxExport, DxTitle,
        DxFont, DxSize, DxMargin, DxLegend, DxTooltip
    },
    props: {
        type: String,
    },
    computed: {
        dataGrid() {
            return this.$refs.dataGrid.instance;
        },
    },
    data() {
        return {
            joinToday: '',
            joinWhole: '',
            visitToday: '',
            dataSource: [],
            dataCount: [],
            dataSource2: []
        }

    },
    async mounted() {
        await this.initData();
    },
    methods: {
        async initData() {
            let parameters = []; //초기값은 현재 년도, 전체
            let param = {}; //하나의 쌍
            let tempRownum = 1;
            param["START_DATE"] = '';
            param["END_DATE"] = '';
            param["YEAR"] = 2024; //초기값 올해 년도
            parameters.push(param);
            this.dataSource = [];
            this.dataCount = [];
            this.dataSource = await DataHelper.InitAddinDownloadCount(parameters);
            this.dataSource2 = await DataHelper.InitAddinExecuteCount(parameters);
            for (var i = 0; i < this.dataSource.length; i++) {
                this.dataSource[i]["rownum"] = tempRownum++;
            }
            tempRownum = 1;
            for (var i = 0; i < this.dataSource2.length; i++) {
                this.dataSource2[i]["rownum"] = tempRownum++;
            }
            this.dataCount = await DataHelper.InitHomeData();
            this.joinToday = this.dataCount[0]["joinToday"];
            this.joinWhole = this.dataCount[0]["joinWhole"];
            this.visitToday = this.dataCount[0]["visitToday"];

        }
    }
}
</script>

<style scoped>
* {
    box-sizing: border-box;
}

.wrapper {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}

.Content_Container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    padding: 30px 10px;
}

.item_Container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px
}

.item_Container .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 31%;
    height: 100px;
    text-align: center;
    border: solid 1px;
    border-radius: 5px;
}

.Content_Container .item_Container .item p {
    font-size: 3rem;
}

.table_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.table_container .table_down {
    width: 49%
}

.table_container .table_execute {
    width: 49%;
}
</style>