import { createRouter, createWebHistory } from 'vue-router'
import LogIn from '@/components/LogIn.vue'
import MainView from '@/components/MainView.vue'
import TestPage from '@/components/TestPage_View.vue'
import Cookies from 'js-cookie';

const requireLogin = () => (to, from, next) => {
  if (Cookies.get('btoken') != undefined) {
      next();
  } else {
      alert('접근 권한이 없습니다.');
      router.push({ path: '/' })
      return;
  }
};

const loggedIn = () => (to, from, next) => {
  if(Cookies.get('btoken') == undefined) {
      next();
  } else {
      router.push({path: '/main'});
  }
}

const routes = [
  {
    path: '/',
    name: 'LogIn',
    component: LogIn,
    beforeEnter: loggedIn()
  },
  {
    path: '/main',
    name: 'main',
    component: MainView,
    beforeEnter: requireLogin(),
  },
  {
    path: '/test',
    name: 'test',
    component: TestPage
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
