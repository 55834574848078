import RestHelper from '../Helpers/RestHelper'
export default class DataHelper{
    static async InitSujuData()
    {
        const res = await RestHelper.GetOrder();
        const data = res._data;
        // for(let o of data)
        // {
        //     o.SUJU_WAL = this.SetDtData(o.SUJUIL_DT,true);
        //     o.GYEYAKIL_DT = this.SetDtData(o.GYEYAKIL_DT);
        //     o.SUJUIL_DT = this.SetDtData(o.SUJUIL_DT);
        // }
        return data;
    }
    static async InitHomeData() {
        const res = await RestHelper.GetHomeData();
        const data = res._data;
        return data;
    }
    static async InitAddinExecuteCount(parameters) {
        const res = await RestHelper.GetAddinCount(parameters);
        const data = res._data;
        return data;
    }
    static async InitAddinExecuteCount_date(parameters) {
        const res = await RestHelper.GetAddinCount_date(parameters);
        const data = res._data;
        return data;
    }
    static async InitAddinExecuteChart(parameters) {
        const res = await RestHelper.GetAddinChart(parameters);
        const data = res._data;
        return data;
    }
    static async InitAddinExecuteChart_time(parameters) {
        const res = await RestHelper.GetAddinChart_time(parameters);
        const data = res._data;
        return data;
    }
    static async InitAddinExecuteChart_datetime(parameters) {
        const res = await RestHelper.GetAddinChart_datetime(parameters);
        const data = res._data;
        return data;
    }
    static async InitAddinExecuteCountMonth(parameters) {
        const res = await RestHelper.GetAddinCountMonth(parameters);
        const data = res._data;
        return data;
    }
    static async InitAddinExecuteCountInit() {
        const res = await RestHelper.GetAddinInit();
        const data = res._data;
        return data;
    }
    static async InitAddinExecuteChartMonth(parameters) {
        const res = await RestHelper.GetAddinChartMonth(parameters);
        const data = res._data;
        return data;
    }
    static async InitJoinCount(parameters) {
        const res = await RestHelper.GetJoinCount(parameters);
        const data = res._data;
        return data;
    }
    static async InitJoinCountMonth(parameters) {
        const res = await RestHelper.GetJoinCountMonth(parameters);
        const data = res._data;
        return data;
    }
    static async InitAddinExecute(parameters) {
        const res = await RestHelper.GetAddinData(parameters);
        const data = res._data;
        return data;
    }
    static async InitUserData() {
        const res = await RestHelper.GetUserData();
        const data = res._data;
        return data;
    }
    static async InitVisitCount(parameters) {
        const res = await RestHelper.GetVisitData(parameters);
        const data = res._data;
        return data;
    }
    static async InitVisitCountMonth(parameters) {
        const res = await RestHelper.GetVisitDataMonth(parameters);
        const data = res._data;
        return data;
    }
    static async InitAddinDownloadCount(parameters) {
        const res = await RestHelper.GetAddinDownloadData(parameters);
        const data = res._data;
        return data;
    }
    static async InitAddinDownloadCount_date(parameters) {
        const res = await RestHelper.GetAddinDownloadData_date(parameters);
        const data = res._data;
        return data;
    }
    static async InitAddinDownloadChart(parameters) {
        const res = await RestHelper.GetDownloadChart(parameters);
        const data = res._data;
        return data;
    }
    static async InitAddinDownloadChart_time(parameters) {
        const res = await RestHelper.GetDownloadChart_time(parameters);
        const data = res._data;
        return data;
    }
    static async InitAddinDownloadChart_datetime(parameters) {
        const res = await RestHelper.GetDownloadChart_datetime(parameters);
        const data = res._data;
        return data;
    }
    static async InitAddinDownloadCountMonth(parameters) {
        const res = await RestHelper.GetAddinDownloadDataMonth(parameters);
        const data = res._data;
        return data;
    }
    static async InitAddinDownloadChartMonth(parameters) {
        const res = await RestHelper.GetDownloadChartMonth(parameters);
        const data = res._data;
        return data;
    }
    static async InitAddinDownloadCountInit() {
        const res = await RestHelper.GetAddinDownloadInit();
        const data = res._data;
        return data;
    }
}